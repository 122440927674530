import { useSplitTreatments } from '@splitsoftware/splitio-react';

import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { ACTIVE_SPLITS } from '../constants';

export type SplitState = 'loading' | boolean;

export const useSplit = (
  name: ACTIVE_SPLITS,
  accountId?: string,
  splitAttributes?: SplitIO.Attributes
): SplitState => {
  const { treatments, isReady }: ISplitTreatmentsChildProps =
    useSplitTreatments({
      names: Object.values(ACTIVE_SPLITS),
      splitKey: accountId,
      attributes: splitAttributes,
    });

  if (!isReady) {
    return 'loading';
  }

  return treatments[name].treatment === 'on';
};
