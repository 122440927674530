import { useAuth0 } from '@auth0/auth0-react';
import { Patient } from '@galileo/core-api-client';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FullPageLoading } from 'components';
import { API, flaskApiClient } from 'constants/apiEndpoints';
import { parse } from 'date-fns';
import { NewUserOnboarding } from 'features/NewUserOnboarding';
import { PediatricsIneligible } from 'features/NewUserOnboarding/PediatricsIneligible';
import { searchPatientService } from 'features/NewUserOnboarding/services';
import { RegistrationCompleteLockout } from 'features/pg-RegistrationCompleteLockout';
import { isUnder18FromDOB } from 'helpers';
import { AnalyticsSourceFlow } from 'helpers/patientAnalytics';
import { Account, ApiResponse, IdVerificationStatusCode } from 'models';
import { create } from 'zustand';

type IntakeSteps = 'INTAKE' | 'ADDRESS_ENTRY' | 'ID_UPLOAD' | 'CONFIRMATION';

const determinePhotoIdStatus = async (account: Account): Promise<boolean> => {
  if (!account) return false;
  if (
    account.status_identity_verification !== IdVerificationStatusCode.Unverified
  ) {
    return true;
  }

  let data;
  try {
    const photoResponse = await flaskApiClient.get(
      API.accountImagesIdentification(account.account_id),
      {
        responseType: 'blob',
      }
    );
    data = photoResponse.data;
  } catch (error) {
    return false;
  }
  return data != null;
};

const hasCompleteAddress = (account: Account): boolean => {
  if (!account) return false;
  const { address_street, address_city, address_state, address_zip } = account;
  if (address_street && address_city && address_state && address_zip) {
    return true;
  }
  return false;
};

const isPediatric = (account: Account): boolean => {
  if (!account) return false;
  const { date_of_birth } = account;
  if (!date_of_birth) return false;
  const parsedDate = parse(account.date_of_birth, 'yyyy-MM-dd', new Date());
  return isUnder18FromDOB(parsedDate);
};

const getIntakeFlow = (
  account: Account,
  hasPhotoId: boolean
): IntakeSteps[] => {
  const flow: IntakeSteps[] = ['INTAKE'];

  if (!hasCompleteAddress(account)) flow.push('ADDRESS_ENTRY');

  if (!hasPhotoId) flow.push('ID_UPLOAD');

  flow.push('CONFIRMATION');
  return flow;
};

type State = {
  patientAccount: Account | null;
  patient: Patient | null;
  setPatientAccount: (account: Account) => void;
  setPatient: (patient: Patient) => void;
  isOnboarded: boolean;
  canOpenCases: boolean;
  canAccessWeb: boolean;
  isPediatricPatient: boolean;
  hasPediatricAccess: boolean;
  intakeFlow: IntakeSteps[];
  setIntakeFlow: (flow: IntakeSteps[]) => void;
  splitAttributes: SplitIO.Attributes | undefined;
};

const useStore = create<State>()(set => ({
  patientAccount: null,
  patient: null,
  isOnboarded: false,
  canOpenCases: false,
  canAccessWeb: false,
  isPediatricPatient: false,
  hasPediatricAccess: false,
  intakeFlow: [],
  setIntakeFlow: flow => set({ intakeFlow: flow }),
  setPatientAccount: account =>
    set({
      patientAccount: account,
      isOnboarded: account.registration_state === 'verified',
      canOpenCases: account.entitlements.includes('can_open_cases'),
      canAccessWeb: account.entitlements.includes('can_access_web'),
      isPediatricPatient: isPediatric(account),
      hasPediatricAccess: account.entitlements.includes('pediatrics'),
    }),
  setPatient: patient => set({ patient }),
  splitAttributes: undefined,
}));

// eslint-disable-next-line react-refresh/only-export-components
export function usePatient() {
  const queryClient = useQueryClient();
  const [
    patientAccount,
    patient,
    isOnboarded,
    canOpenCases,
    canAccessWeb,
    isPediatricPatient,
    hasPediatricAccess,
    intakeFlow,
    setIntakeFlow,
  ] = useStore(state => [
    state.patientAccount,
    state.patient,
    state.isOnboarded,
    state.canOpenCases,
    state.canAccessWeb,
    state.isPediatricPatient,
    state.hasPediatricAccess,
    state.intakeFlow,
    state.setIntakeFlow,
  ]);
  if (!patientAccount) throw new Error('Invalid account');

  return {
    accountId: (patientAccount as Account).account_id,
    patientId: (patient as Patient).id,
    patientAccount: patientAccount as Account,
    patient: patient as Patient,
    isOnboarded,
    canOpenCases,
    canAccessWeb,
    isPediatricPatient,
    hasPediatricAccess,
    intakeFlow,
    setIntakeFlow,
    refetchAccount: () => {
      // Invalidate patient-related queries and refetch them
      // NOTE: Each queryKey array is a separate key
      queryClient.invalidateQueries({
        queryKey: ['patientAccount'],
      });
      queryClient.invalidateQueries({
        queryKey: ['patientProfile'],
      });
    },
    splitAttributes: {
      patient_sponsor: patientAccount.membership?.group?.name,
    } as SplitIO.Attributes,
  };
}

type OnboardedPatientProviderProps = {
  children: React.ReactNode;
};

export function OnboardedPatientProvider(props: OnboardedPatientProviderProps) {
  const { children } = props;
  const { user } = useAuth0();
  const accountId = user?.['https://galileo.io/user_metadata.account_id'];
  const [
    setPatientAccount,
    setPatient,
    isOnboarded,
    canOpenCases,
    canAccessWeb,
    isPediatricPatient,
    hasPediatricAccess,
    setIntakeFlow,
  ] = useStore(state => [
    state.setPatientAccount,
    state.setPatient,
    state.isOnboarded,
    state.canOpenCases,
    state.canAccessWeb,
    state.isPediatricPatient,
    state.hasPediatricAccess,
    state.setIntakeFlow,
  ]);

  const accountQuery = useQuery({
    queryKey: ['patientAccount', accountId],
    queryFn: async () => {
      const response = await flaskApiClient.get<ApiResponse<Account>>(
        API.account(accountId)
      );
      const account = response.data.data[0];
      setPatientAccount(account);

      const hasPhotoId = await determinePhotoIdStatus(account);

      // Set post intake flow directly from the account
      const flow = getIntakeFlow(account, hasPhotoId);
      setIntakeFlow(flow);

      return account;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const patientQuery = useQuery({
    queryKey: ['patientProfile', accountId],
    queryFn: async () => {
      const [patient] = await searchPatientService(accountId);
      setPatient(patient);
      return patient;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const screenSourceFlow: AnalyticsSourceFlow = 'provider_onboarding';

  if (accountQuery.isLoading || patientQuery.isLoading)
    return <FullPageLoading />;
  if (!accountId) throw new Error('Invalid user');

  if (!isOnboarded) return <NewUserOnboarding />;
  if (isOnboarded && isPediatricPatient && !hasPediatricAccess)
    return <PediatricsIneligible screenSourceFlow={screenSourceFlow} />;
  if (isOnboarded && !canOpenCases)
    return <RegistrationCompleteLockout screenSourceFlow={screenSourceFlow} />;
  if (isOnboarded && !canAccessWeb)
    return <RegistrationCompleteLockout screenSourceFlow={screenSourceFlow} />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
